/**
 * Site header
 */
.site-header {
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;
  background: linear-gradient(
    to right,
    rgba(241, 255, 190, 0.5),
    rgba(255, 156, 26, 0.7),
    rgba(255, 246, 74, 0.7)
  );
  position: relative;

  font-family: "Work Sans";
  font-weight: 300;
  backdrop-filter: blur(20px); /* This adds a translucent effect */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid rgba(255, 255, 255, 0.5); /* Light border for glass effect */
}

.title-logo {
  display: none;
}

@media (min-width: 1200px) {
  .title-logo {
    display: block;
    height: 190px;
    position: absolute;
    margin-top: 15px;
    margin-left: 4%;
  }
}

.site-title {
  @include relative-font-size(1.725);
  font-family: "Space Grotesk";
  font-weight: 500;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }

  &,
  &:hover {
    text-decoration: none;
  }
}

.site-title-ai-showcase {
  background: linear-gradient(90deg, #ffdfec, #ffcadf, #cdfeff);
  color: rgb(0, 0, 0);
  padding: 10px;
  border-radius: 10px; /* Rounded rectangle */
  display: inline-block;
  text-align: center;
  font-size: small;
  text-decoration: none;
  margin-left: calc(#{$spacing-unit} / 2);
  margin-top: calc(#{$spacing-unit} / 4);
  font-weight: bold;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(0.97);
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    text-transform: lowercase;
    font-weight: 700;
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }

    &,
    &:hover {
      text-decoration: none;
      color: darken($brand-color, 5%);
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: calc(#{$spacing-unit} / 2);
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;
      margin-left: 20px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

/**
 * Site footer with glass effect and gradient
 */
.site-footer {
  border-top: 5px solid rgba(255, 255, 255, 0.5); /* Light border for glass effect */
  padding: $spacing-unit 0;
  background: linear-gradient(
    90deg,
    rgba(241, 255, 190, 0.5),
    rgba(255, 156, 26, 0.7),
    rgba(255, 246, 74, 0.7)
  );
  backdrop-filter: blur(10px); /* Blur effect for the glass effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  color: rgba(48, 48, 48, 0.8);

  .username,
  .u-email,
  span {
    color: rgba(48, 48, 48, 0.8);
  }

  svg {
    fill: rgba(48, 48, 48, 0.8); /* Apply the desired color overlay */
  }
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: calc(#{$spacing-unit} / 2);
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  margin-left: calc(-#{$spacing-unit} / 2);
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: calc(#{$spacing-unit} / 2);
  padding-left: calc(#{$spacing-unit} / 2);
}

.footer-col-1 {
  width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1 0 auto;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title,
.post-content h1 {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
