/**
 * Syntax highlighting styles
 */
.highlight {
  background: #fdf6e3;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: #fdf6e3;
  }

  .c {
    color: #93a1a1;
  } /* Comment */
  .err {
    color: #586e75;
  } /* Error */
  .g {
    color: #586e75;
  } /* Generic */
  .k {
    color: #859900;
  } /* Keyword */
  .l {
    color: #586e75;
  } /* Literal */
  .n {
    color: #363633;
  } /* Name */
  .o {
    color: #859900;
  } /* Operator */
  .x {
    color: #cb4b16;
  } /* Other */
  .p {
    color: #586e75;
  } /* Punctuation */
  .cm {
    color: #93a1a1;
  } /* Comment.Multiline */
  .cp {
    color: #859900;
  } /* Comment.Preproc */
  .c1 {
    color: #93a1a1;
  } /* Comment.Single */
  .cs {
    color: #859900;
  } /* Comment.Special */
  .gd {
    color: #2aa198;
  } /* Generic.Deleted */
  .ge {
    color: #586e75;
    font-style: italic;
  } /* Generic.Emph */
  .gr {
    color: #dc322f;
  } /* Generic.Error */
  .gh {
    color: #cb4b16;
  } /* Generic.Heading */
  .gi {
    color: #859900;
  } /* Generic.Inserted */
  .go {
    color: #586e75;
  } /* Generic.Output */
  .gp {
    color: #586e75;
  } /* Generic.Prompt */
  .gs {
    color: #586e75;
    font-weight: bold;
  } /* Generic.Strong */
  .gu {
    color: #cb4b16;
  } /* Generic.Subheading */
  .gt {
    color: #586e75;
  } /* Generic.Traceback */
  .kc {
    color: #cb4b16;
  } /* Keyword.Constant */
  .kd {
    color: #268bd2;
  } /* Keyword.Declaration */
  .kn {
    color: #859900;
  } /* Keyword.Namespace */
  .kp {
    color: #859900;
  } /* Keyword.Pseudo */
  .kr {
    color: #268bd2;
  } /* Keyword.Reserved */
  .kt {
    color: #dc322f;
  } /* Keyword.Type */
  .ld {
    color: #586e75;
  } /* Literal.Date */
  .m {
    color: #2aa198;
  } /* Literal.Number */
  .s {
    color: #2aa198;
  } /* Literal.String */
  .na {
    color: #586e75;
  } /* Name.Attribute */
  .nb {
    color: #b58900;
  } /* Name.Builtin */
  .nc {
    color: #268bd2;
  } /* Name.Class */
  .no {
    color: #cb4b16;
  } /* Name.Constant */
  .nd {
    color: #268bd2;
  } /* Name.Decorator */
  .ni {
    color: #cb4b16;
  } /* Name.Entity */
  .ne {
    color: #cb4b16;
  } /* Name.Exception */
  .nf {
    color: #268bd2;
  } /* Name.Function */
  .nl {
    color: #586e75;
  } /* Name.Label */
  .nn {
    color: #363633;
  } /* Name.Namespace */
  .nx {
    color: #586e75;
  } /* Name.Other */
  .py {
    color: #586e75;
  } /* Name.Property */
  .nt {
    color: #268bd2;
  } /* Name.Tag */
  .nv {
    color: #268bd2;
  } /* Name.Variable */
  .ow {
    color: #859900;
  } /* Operator.Word */
  .w {
    color: #586e75;
  } /* Text.Whitespace */
  .mf {
    color: #2aa198;
  } /* Literal.Number.Float */
  .mh {
    color: #2aa198;
  } /* Literal.Number.Hex */
  .mi {
    color: #2aa198;
  } /* Literal.Number.Integer */
  .mo {
    color: #2aa198;
  } /* Literal.Number.Oct */
  .sb {
    color: #93a1a1;
  } /* Literal.String.Backtick */
  .sc {
    color: #2aa198;
  } /* Literal.String.Char */
  .sd {
    color: #586e75;
  } /* Literal.String.Doc */
  .s2 {
    color: #2aa198;
  } /* Literal.String.Double */
  .se {
    color: #cb4b16;
  } /* Literal.String.Escape */
  .sh {
    color: #586e75;
  } /* Literal.String.Heredoc */
  .si {
    color: #2aa198;
  } /* Literal.String.Interpol */
  .sx {
    color: #2aa198;
  } /* Literal.String.Other */
  .sr {
    color: #dc322f;
  } /* Literal.String.Regex */
  .s1 {
    color: #2aa198;
  } /* Literal.String.Single */
  .ss {
    color: #2aa198;
  } /* Literal.String.Symbol */
  .bp {
    color: #268bd2;
  } /* Name.Builtin.Pseudo */
  .vc {
    color: #268bd2;
  } /* Name.Variable.Class */
  .vg {
    color: #268bd2;
  } /* Name.Variable.Global */
  .vi {
    color: #268bd2;
  } /* Name.Variable.Instance */
  .il {
    color: #2aa198;
  } /* Literal.Number.Integer.Long */
}
